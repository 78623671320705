import { Injectable } from '@angular/core'

export const SIGNUP_DETAILS_STORAGE = 'signupDetails'

@Injectable({ providedIn: 'root' })
export class SharedService {
  private readonly sharedDataStorage = 'sharedData'
  data = {}

  constructor() {}

  getData(key: string) {
    // Get value from service data container if exists
    // @ts-ignore
    if (this.data[key] !== null && this.data[key] !== undefined) {
      // @ts-ignore
      return this.data[key]
    }

    // Try to get value from storage data container
    const sharedData = localStorage.getItem(this.sharedDataStorage)
    // istanbul ignore next
    const storageData = sharedData ? JSON.parse(sharedData) : {}
    if (storageData.hasOwnProperty(key)) return storageData[key]

    return null
  }

  reset(): void {
    this.data = {}
    localStorage.removeItem(this.sharedDataStorage)
  }

  resetData(key: string): void {
    // Reset service data container
    if (this.data.hasOwnProperty(key)) {
      // @ts-ignore
      delete this.data[key]
    }

    // Reset storage data container
    const sharedData = localStorage.getItem(this.sharedDataStorage)
    const storageData = sharedData ? JSON.parse(sharedData) : {}
    delete storageData[key]
    localStorage.setItem(this.sharedDataStorage, JSON.stringify(storageData))
  }

  setData(key: string, data: any): void {
    // Init service data container
    this.data[key] = data

    // Init storage data container
    const sharedData = localStorage.getItem(this.sharedDataStorage)
    // istanbul ignore next
    const storageData = sharedData ? JSON.parse(sharedData) : {}
    storageData[key] = data
    localStorage.setItem(this.sharedDataStorage, JSON.stringify(storageData))
  }
}
