import * as i0 from '@angular/core';
import { Directive, Injectable, Component, NgModule } from '@angular/core';
import { catchError, of, map } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
class NgxGfmoneyPasswordPreviewDirective {
  get iconShow() {
    return `${this.iconPath}/eye.svg`;
  }
  get iconHide() {
    return `${this.iconPath}/eye-closed.svg`;
  }
  constructor(renderer, el) {
    this.renderer = renderer;
    this.el = el;
    this.iconPath = '../../../assets/images/icons';
    this.renderer.setAttribute(el.nativeElement, 'style', 'position: relative');
    this.icon = this.renderer.createElement('img');
    this.icon.setAttribute('src', this.iconShow);
    this.icon.setAttribute('style', 'width: 16px; position: absolute; top: calc(50% - 8px); right: 15px; width: 16px; cursor: pointer;');
    this.icon.addEventListener('click', this.handleIconClick.bind(this));
    this.renderer.appendChild(el.nativeElement, this.icon);
  }
  handleIconClick() {
    const input = this.el.nativeElement.querySelector('input');
    const type = input.getAttribute('type');
    input.setAttribute('type', type === 'text' ? 'password' : 'text');
    this.icon.setAttribute('src', type === 'text' ? this.iconShow : this.iconHide);
  }
  ngOnDestroy() {
    this.icon.removeEventListener('click', this.handleIconClick);
  }
  static {
    this.ɵfac = function NgxGfmoneyPasswordPreviewDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxGfmoneyPasswordPreviewDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxGfmoneyPasswordPreviewDirective,
      selectors: [["", "ngxGfmoneyPasswordPreview", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGfmoneyPasswordPreviewDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxGfmoneyPasswordPreview]'
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], null);
})();
const exceptionalDomains = ['jubii.dk'];
function emailVerificationValidator(libService) {
  return control => {
    const domain = control.value.split('@')[1] ?? '';
    if (exceptionalDomains.includes(domain)) {
      return Promise.resolve(null);
    }
    return libService.verifyEmail(control.value).pipe(catchError(() => of({
      status: 'verification-limit'
    })), map(response => response.status && ['valid', 'catch-all'].includes(response.status) ? null : response.status === 'verification-limit' ? {
      emailVerificationLimit: true
    } : {
      emailInvalid: true
    }));
  };
}

// istanbul ignore file
class Config {
  constructor() {
    this.servicesUrl = '';
  }
}
class NgxGfmoneyLibService {
  constructor(http, config) {
    this.http = http;
    this.config = config;
  }
  verifyEmail(email) {
    return this.http.get(`${this.config.servicesUrl}/services/v1/verification/email`, {
      params: new HttpParams().set('email', email)
    });
  }
  static {
    this.ɵfac = function NgxGfmoneyLibService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxGfmoneyLibService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(Config));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxGfmoneyLibService,
      factory: NgxGfmoneyLibService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGfmoneyLibService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: Config
  }], null);
})();
class NgxGfmoneyLibComponent {
  static {
    this.ɵfac = function NgxGfmoneyLibComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxGfmoneyLibComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxGfmoneyLibComponent,
      selectors: [["ngx-gfmoney-lib"]],
      decls: 2,
      vars: 0,
      template: function NgxGfmoneyLibComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "p");
          i0.ɵɵtext(1, "ngx-gfmoney-lib works!");
          i0.ɵɵelementEnd();
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGfmoneyLibComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-gfmoney-lib',
      template: ` <p>ngx-gfmoney-lib works!</p> `
    }]
  }], null, null);
})();
class NgxGfmoneyLibModule {
  static forRoot(config) {
    return {
      ngModule: NgxGfmoneyLibModule,
      providers: [{
        provide: Config,
        useValue: config
      }]
    };
  }
  static forChild(config) {
    return {
      ngModule: NgxGfmoneyLibModule,
      providers: [{
        provide: Config,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function NgxGfmoneyLibModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxGfmoneyLibModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxGfmoneyLibModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGfmoneyLibModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxGfmoneyLibComponent, NgxGfmoneyPasswordPreviewDirective],
      imports: [],
      exports: [NgxGfmoneyLibComponent, NgxGfmoneyPasswordPreviewDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-gfmoney-lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxGfmoneyLibComponent, NgxGfmoneyLibModule, NgxGfmoneyLibService, NgxGfmoneyPasswordPreviewDirective, emailVerificationValidator };
