// istanbul ignore file

import { createReducer, on } from '@ngrx/store'
import { AppActions } from './app.actions'

export interface AppStoreState {
  noticeVisible: boolean
}

export const initialState: AppStoreState = { noticeVisible: true }

export const appReducer = createReducer(
  initialState,
  on(AppActions.showNotice, state => ({ ...state, noticeVisible: true })),
  on(AppActions.hideNotice, state => ({ ...state, noticeVisible: false })),
)
