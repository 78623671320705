import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class InitService {
  constructor(private router: Router) {}

  // istanbul ignore next
  init() {
    return new Promise<void>((resolve, reject) => {
      // router.resetConfig(filteredRoutes);
      resolve()
    })
  }
}
