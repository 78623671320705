import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http'
import { environment } from '@environments/environment'
import {
  AccountsResponseDto,
  AggregatedCustomerData,
  BankIdRequestResult,
  BankIdResponse,
  BankIdSignatures,
  CreditLimitUpgradeStatus,
  HomePageDetails,
  IncomeCheckStatus,
  // IncomeCheckStatus,
  InternalChecksStatus,
  InvoicesResponse,
  PaymentFreeMonthResult,
  PaymentFreeMonthStatus,
  SignupDetails,
  TransactionsReportResponse,
  TransactionsResponse,
  UnreadMessagesCountResponseDto,
  UserDataResponse,
} from '../interfaces'
import { catchError, tap } from 'rxjs/operators'
import { Observable, Subject, throwError } from 'rxjs'

@Injectable()
export class ApiService {
  static MessageVisibleTime = 30000
  error$: Subject<string> = new Subject<string>()
  errorSlot$: Subject<string> = new Subject<string>()
  success$: Subject<string> = new Subject<string>()
  successSlot$: Subject<string> = new Subject<string>()

  constructor(private http: HttpClient) {}

  fetchErrorFromResponse(errorResponse: HttpErrorResponse): string {
    // istanbul ignore next
    return errorResponse?.error?.error ? errorResponse?.error?.error.alias : errorResponse.message
  }

  handleError(errorResponse: HttpErrorResponse) {
    const error = this.fetchErrorFromResponse(errorResponse)
    this.error$.next(error)
    this.success$.next('')
    setTimeout(() => this.error$.next(''), ApiService.MessageVisibleTime)
    return throwError(errorResponse)
  }

  handleErrorSlot(errorResponse: HttpErrorResponse) {
    const error = this.fetchErrorFromResponse(errorResponse)
    this.errorSlot$.next(error)
    this.successSlot$.next('')
    setTimeout(() => this.errorSlot$.next(''), ApiService.MessageVisibleTime)
    return throwError(errorResponse)
  }

  handleSuccess(success: any) {
    this.error$.next('')
    this.success$.next(success)
    setTimeout(() => this.success$.next(''), ApiService.MessageVisibleTime)
  }

  handleSuccessSlot(success: any) {
    this.errorSlot$.next('')
    this.successSlot$.next(success)
    setTimeout(() => this.successSlot$.next(''), ApiService.MessageVisibleTime)
  }

  getBankIdAuthData(params = {}): Observable<BankIdResponse> {
    return this.http
      .post<BankIdResponse>(`/api/public/v1/bankid/auth`, params)
      .pipe(catchError(err => this.handleError(err)))
  }

  getBankIdRequestResult(
    orderRef: string,
    notAuthorize: string,
    signDocument: string | BankIdSignatures,
  ): Observable<BankIdRequestResult> {
    let params = new HttpParams().set('orderRef', orderRef)
    if (notAuthorize) {
      params = params.append('notAuthorize', 'true')
    }
    if (signDocument) {
      params = params.append('signDocument', signDocument)
    }
    return this.http
      .get<BankIdRequestResult>(`/api/public/v1/bankid/auth/result`, { params })
      .pipe(catchError(err => this.handleError(err)))
  }

  getLoanAndCustomerByGuid(guid: string, brokers: boolean = false): Observable<AggregatedCustomerData> {
    let params = {}
    if (brokers) params = new HttpParams().set('brokers', 'true')
    return this.http
      .get<AggregatedCustomerData>(`/api/public/v1/loans/guid/${guid}`, { params })
      .pipe(catchError(err => this.handleError(err)))
  }

  passwordRecovery(user: any) {
    return this.http.post(`/api/public/v1/users/password-recovery`, { user }).pipe(
      tap(() => this.handleSuccess('ForgotPasswordPage.success')),
      catchError(err => this.handleError(err)),
    )
  }

  sendVerificationCode(user: SignupDetails): Observable<any> {
    return this.http
      .post<any>(`/api/public/v1/loan-flow/send-verification-code`, { user })
      .pipe(catchError(err => this.handleError(err)))
  }

  verifyPhoneCode(user: SignupDetails): Observable<any> {
    return this.http
      .post<any>(`/api/public/v1/loan-flow/phone-verification`, { user })
      .pipe(catchError(err => this.handleError(err)))
  }

  getLoan(loanId): Observable<any> {
    return this.http.get<any>(`/api/v1/loans/${loanId}`).pipe(catchError(err => this.handleError(err)))
  }

  checkIsLoanEligibleForInsurance(): Observable<any> {
    return this.http.get<any>(`/api/v1/loans/insurance/eligibility`).pipe(catchError(err => this.handleError(err)))
  }

  updateLoan(data, loanId): Observable<any> {
    return this.http.patch<any>(`/api/v1/loans/${loanId}`, { data }).pipe(catchError(err => this.handleError(err)))
  }

  updateLoanConsents(data): Observable<any> {
    return this.http.patch<any>(`/api/v1/loan-flow/consents`, { data }).pipe(catchError(err => this.handleError(err)))
  }

  updateCustomerDetails(data, internalChecks: boolean = false): Observable<{ loan: any; user: any }> {
    return this.http
      .patch<{
        loan: any
        user: any
      }>(`/api/v1/customers/self`, { data }, { params: new HttpParams().set('internalChecks', internalChecks) })
      .pipe(catchError(err => this.handleError(err)))
  }

  contractSign(data): Observable<any> {
    return this.http.patch<any>(`/api/v1/contracts/sign`, { data }).pipe(catchError(err => this.handleError(err)))
  }

  getLoanTermsAndConditions(amount): Observable<any> {
    return this.http
      .get(`/api/v1/loan-flow/terms-and-conditions`, { params: new HttpParams().set('withdraw_amount', amount) })
      .pipe(catchError(err => this.handleError(err)))
  }

  getInternalChecksStatus(state: string = ''): Observable<InternalChecksStatus> {
    const params = state ? new HttpParams().set('state', state) : {}
    return this.http
      .get<InternalChecksStatus>(`/api/v1/loan-flow/internal-checks`, { params })
      .pipe(catchError(err => this.handleError(err)))
  }

  logTransactionsCheckError(errorData: string): Observable<any> {
    return this.http.post<any>(`/api/v1/loan-flow/transactions/request/error`, { errorData })
  }

  sendTransactionsRequestData(report: TransactionsReportResponse): Observable<{ accounts: [] }> {
    return this.http
      .post<{ accounts: [] }>(`/api/v1/loan-flow/transactions/request/data`, { data: report })
      .pipe(catchError(err => this.handleError(err)))
  }

  updateCustomerAccount(account): Observable<any> {
    return this.http.patch<any>(`/api/v1/customers/account`, { account }).pipe(
      tap(() => this.handleSuccess('AccountSelection.accountUpdated')),
      catchError(err => this.handleError(err)),
    )
  }

  selectedCustomerAccount(): Observable<any> {
    return this.http.post<any>(`/api/v1/customers/account/selection`, {}).pipe(catchError(err => this.handleError(err)))
  }

  getCreditLine(): Observable<any> {
    return this.http.get<any>(`/api/v1/loans`).pipe(catchError(err => this.handleError(err)))
  }

  getAccountsDetails(): Observable<AccountsResponseDto> {
    return this.http
      .get<AccountsResponseDto>(`/api/v3/customers/self/accounts`)
      .pipe(catchError(err => this.handleError(err)))
  }

  trackCustomerData(cid: string, session: string, affiliateData: string, data: any): Observable<any> {
    data.cid = cid
    data.session = session
    data.affiliate_data = affiliateData
    return this.http.post(`/api/public/v1/track/data`, { data })
  }

  trackCustomerNavigation(session: string, event: object): Observable<any> {
    return this.http
      .post(`/api/public/v1/track/navigation`, { data: { session, event } })
      .pipe(catchError(err => this.handleError(err)))
  }

  authUser(link_id: string): Observable<any> {
    return this.http.post<any>(`/api/public/v1/users/auth`, { link_id }).pipe(catchError(err => this.handleError(err)))
  }

  getIncomeCheckLink(): Observable<{ link: string }> {
    return this.http
      .get<{ link: string }>(`/api/v1/loan-flow/income-check/link`)
      .pipe(catchError(err => this.handleError(err)))
  }

  getIncomeCheckStatus(): Observable<IncomeCheckStatus> {
    return this.http
      .get<IncomeCheckStatus>(`/api/v1/loan-flow/income-check/status`)
      .pipe(catchError(err => this.handleError(err)))
  }

  // My pages
  getCreditLimitUpgradeStatus(): Observable<CreditLimitUpgradeStatus> {
    return this.http
      .get<CreditLimitUpgradeStatus>(`/api/v1/mypages/user/credit-limit-upgrade`)
      .pipe(catchError(err => this.handleError(err)))
  }

  applyToPaymentFreeMonth(): Observable<PaymentFreeMonthResult> {
    return this.http
      .post<PaymentFreeMonthResult>(`/api/v1/mypages/users/payment-free-month`, {})
      .pipe(catchError(err => this.handleError(err)))
  }

  checkIfCanGetFreeMonth(): Observable<PaymentFreeMonthStatus> {
    return this.http
      .get<PaymentFreeMonthStatus>(`/api/v1/mypages/users/payment-free-month`)
      .pipe(catchError(err => this.handleError(err)))
  }

  renewCustomerDetails(data): Observable<any> {
    return this.http.patch(`/api/v1/mypages/customers`, { data }).pipe(catchError(err => this.handleError(err)))
  }

  downloadInvoice(accountIdApi, letterId, accountLetterType): Observable<Blob> {
    return this.http
      .get<Blob>(`/api/v1/mypages/invoices/${accountIdApi}/${letterId}/${accountLetterType}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getActiveLoan(): Observable<any> {
    return this.http.get<any>(`/api/v1/mypages/loans/active`).pipe(catchError(err => this.handleError(err)))
  }

  getPaymentLink(amount: number, tag: string | undefined, redirectUri: string): Observable<any> {
    let params = new HttpParams().append('amount', Math.ceil(amount).toString()).append('redirectUri', redirectUri)
    if (tag) {
      params = params.append('tag', tag)
    }
    return this.http
      .get<any>(`/api/v1/mypages/users/payments/link`, { params })
      .pipe(catchError(err => this.handleError(err)))
  }

  getInvoices(): Observable<InvoicesResponse> {
    return this.http.get<InvoicesResponse>(`/api/v1/mypages/invoices`).pipe(catchError(err => this.handleError(err)))
  }

  getUnreadMessagesNumber(): Observable<UnreadMessagesCountResponseDto> {
    return this.http
      .get<UnreadMessagesCountResponseDto>('/api/v1/messages/unread')
      .pipe(catchError(err => this.handleError(err)))
  }

  getTransactions(): Observable<TransactionsResponse> {
    return this.http
      .get<TransactionsResponse>(`/api/v1/mypages/transactions`)
      .pipe(catchError(err => this.handleError(err)))
  }

  getHomePageDetails(): Observable<HomePageDetails> {
    return this.http.get<HomePageDetails>(`/api/v1/mypages/home`).pipe(catchError(err => this.handleError(err)))
  }

  getPaymentDetails(date: string): Observable<Array<{ [key: string]: number }>> {
    return this.http
      .get<Array<{ [key: string]: number }>>(`/api/v1/mypages/payments/${date}`)
      .pipe(catchError(err => this.handleError(err)))
  }

  getPaymentPlanExample(credit: number, runtime: number, noStartFee: number, profile: number): Observable<any> {
    return this.http
      .get<any>(`/api/v1/mypages/payments/plan/example`, {
        params: new HttpParams()
          .set('credit', credit)
          .set('runtime', runtime)
          .set('noStartFee', noStartFee)
          .set('profile', profile),
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getUserData(): Observable<UserDataResponse> {
    return this.http
      .get<UserDataResponse>(`/api/v1/mypages/user`)
      .pipe(catchError(err => this.handleError(err)))
  }

  updateCustomerData(data: any): Observable<any> {
    return this.http.patch<any>(`/api/v1/mypages/user`, { user: data }).pipe(
      tap(() => this.handleSuccess('ProfilePage.profileUpdatedSuccess')),
      catchError(err => this.handleError(err)),
    )
  }

  requestWithdraw(amount: number): Observable<any> {
    return this.http.post<any>(`/api/v1/mypages/withdrawals`, { amount }).pipe(
      tap(() => this.handleSuccess('HomePage.withdrawSuccess')),
      catchError(err => this.handleError(err)),
    )
  }

  updateCustomerPin(pin: string): Observable<any> {
    return this.http.patch<any>(`/api/v1/mypages/user/pin`, { pin }).pipe(
      tap(() => this.handleSuccessSlot('ProfilePage.pinUpdatedSuccess')),
      catchError(err => this.handleErrorSlot(err)),
    )
  }

  uploadFile(file: File, endpoint: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData()
    formData.append('file', file)
    const request = new HttpRequest('POST', `${environment.apiHost}${endpoint}`, formData, {
      reportProgress: true,
      responseType: 'json',
    })
    return this.http.request(request)
  }
}
