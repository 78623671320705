import { Component } from '@angular/core'
import { CustomerNavigationService } from './customer-navigation/customer-navigation.service'
import { TranslateService } from '@ngx-translate/core'
import { Event, NavigationEnd, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppActions } from './store/app.actions'

@Component({ selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss'] })
export class AppComponent {
  locale = 'sv'

  constructor(
    private router: Router,
    private customerNavigationService: CustomerNavigationService,
    private translateService: TranslateService,
    private store: Store,
  ) {
    translateService.use(this.locale)
    customerNavigationService.init()

    // istanbul ignore next
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/pay')) {
          this.store.dispatch(AppActions.hideNotice())
        } else {
          this.store.dispatch(AppActions.showNotice())
        }
      }
    })
  }
}
